import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useStateContext } from "../../contexts/ContextProvider";
import { addLeaveType, getLeaveTypes, editLeaveType, deleteLeaveRecord, addLeaveGroup, getLeaveGroups, editLeaveGroup,
          addLeaveBalance, getLeaveBalances, editEmployee, getAccrualRuns, runAccrualRun, getLeavePlannerData, getLeaveSettingsData,
        addPublicHoliday, editPublicHoliday, getMaintenanceMode, toggleMaintenanceMode } from "../../services/leaveService";
import { getEmployees } from '../../services/employeeService';
import { getAvailableBackups, downloadBackup, backupDB, restoreDB, restoreDBFromUpload } from '../../services/filesService';
import CustomDataGrid from '../../components/CustomDataGrid';
import CollapsableCard from '../../components/CollapsableCard';
import Select from 'react-select';
import Save from '@mui/icons-material/Save';
import format from 'date-fns/format';
import CustomSnackBar from '../../components/CustomSnackBar';



const LeaveSettingsPage = () => {
  let { employee, leaveTaken, setLeaveTaken, leaveTypes, setLeaveTypes, leaveGroups, setLeaveGroups, leaveBalances, setLeaveBalances, 
        employees, setEmployees, accrualRuns, setAccrualRuns, setIsLoading, dbBackups, setDBBackups, publicHolidays, setPublicHolidays,
        setSnackbar } = useStateContext(); 

  const [maintenanceMode, setMaintenanceMode] = useState(false);

  useEffect(() => {
    const fetchLeaveValues = async () => {
      setIsLoading(true);
      const maintenance_mode = await getMaintenanceMode();
      setMaintenanceMode(maintenance_mode);

      //set this from all leave taken if admin?
      const leave_planner_data = await getLeavePlannerData(employee, leaveTaken);
      const db_backups = await getAvailableBackups();
      // const employees = await getEmployees();
      let leave_taken = leave_planner_data.leave_taken;
      // let leave_types = leave_planner_data.leave_types;

      const public_holidays = leave_planner_data.public_holidays;
      
      setEmployees(leave_planner_data.employees);
      setAccrualRuns(leave_planner_data.accrual_runs);
      setLeaveTaken(leave_taken);
      setLeaveTypes(leave_planner_data.leave_types);
      setLeaveGroups(leave_planner_data.leave_groups);
      setLeaveBalances(leave_planner_data.leave_balances);
      setPublicHolidays(public_holidays);
      setDBBackups(db_backups);
      setIsLoading(false);
      // setIsInitialLoading(false);

    };
    
    // initialEvents = fetchLeaveValues();
    if (accrualRuns.length === 0){
      fetchLeaveValues();
    } else {
      setIsLoading(false);
      // setIsInitialLoading(false);
    }
  }, []);

  useEffect(() => {

  }, [maintenanceMode]);

  const handleDownloadBackup = async (filename) => {
    setIsLoading(true);
    await downloadBackup(filename);
    setIsLoading(false);
  }

  const handleRestoreBackup = async (filename) => {
    setIsLoading(true);
    await restoreDB(filename);
    setIsLoading(false);
  }

  const handleFileChange = async (event) => {
    // setUploadedBackup(event.target.files[0]);
    setIsLoading(true);
    const result = await restoreDBFromUpload(event.target.files[0]);
    setIsLoading(false);
  };

  const handleRestoreClick = async () => {
    const fileInput = document.getElementById('file-input');
    fileInput.value = null; // Reset the value of the file input
    fileInput.click();
  };

  const handleToggleMaintenanceMode = async () => {
    setIsLoading(true);

    const result = await toggleMaintenanceMode();
    if (result.status === 200){
      setMaintenanceMode(result.data.maintenance_mode);
      setSnackbar({ children: result.data.message, severity: 'success' });
    } else{
      setSnackbar({ children: "Error occurred.", severity: 'error' });
    }

    setIsLoading(false);
  };
  
  //LEAVE TYPES
  let leaveTypesColumns = [
    { field: 'id', headerName: 'Id', width: 20 },
    { field: 'name', headerName: 'Leave Type', width: 300, editable: true },
    {
      field: 'start_time',
      headerName: 'Start Time',
      width: 100,
      type: 'singleSelect',
      valueOptions: Array.from({ length: 48 }, (_, index) => {
        const hour = Math.floor(index / 2) + 0;
        // const hour = Math.floor(index / 2) + 8;
        const minutes = index % 2 === 0 ? '00' : '30';
        return `${hour.toString().padStart(2, '0')}:${minutes}`;
      }),
      editable: true,
    },
    {
      field: 'end_time',
      headerName: 'End Time',
      width: 100,
      type: 'singleSelect',
      valueOptions: Array.from({ length: 48 }, (_, index) => {
        const hour = Math.floor(index / 2) + 0;
        const minutes = index % 2 === 0 ? '00' : '30';
        return `${hour.toString().padStart(2, '0')}:${minutes}`;
      }),
      editable: true,
    },
    { field: 'leave_approvers', headerName: 'Leave Approvers', width: 100, editable: true },   
    { field: 'leave_approvers_type', headerName: 'Approval Type', width: 100, editable: true },   
    {
      field: 'accrual_type',
      headerName: 'Accrual Type',
      width: 100,
      type: 'singleSelect',
      valueOptions: Array.from({ length: 12 }, (_, index) => index + 1),
      editable: true,
    },
    { field: 'accrual_days', headerName: 'Accrual Days', width: 80, editable: true },   
  ];

  const daysColumns = [
    { field: 'monday', headerName: 'Monday', width: 100, type: 'boolean', editable: true },  
    { field: 'tuesday', headerName: 'Tuesday', width: 100, type: 'boolean', editable: true },  
    { field: 'wednesday', headerName: 'Wednesday', width: 100, type: 'boolean', editable: true },  
    { field: 'thursday', headerName: 'Thursday', width: 100, type: 'boolean', editable: true },  
    { field: 'friday', headerName: 'Friday', width: 100, type: 'boolean', editable: true },  
    { field: 'saturday', headerName: 'Saturday', width: 100, type: 'boolean', editable: true },  
    { field: 'sunday', headerName: 'Sunday', width: 100, type: 'boolean', editable: true } 
  ];

  const publicHolidaysNamesColumns = publicHolidays.map(holiday => ({ field: holiday.name, headerName: holiday.name, width: 100, type: 'boolean', editable: true }) );
  
  leaveTypesColumns = leaveTypesColumns.concat(daysColumns);
  leaveTypesColumns = leaveTypesColumns.concat(publicHolidaysNamesColumns);
  
  const addLeaveTypesDictPart = { name: "", accrual_type: "", accrual_days: "", start_time: "", end_time: "",
                              leave_approvers: "", leave_approvers_type: "", 
                              monday: 1, tuesday: 1, wednesday: 1, thursday: 1, friday: 1, saturday: 0, sunday: 0 };

  const addPublicHolidaysDictPart = publicHolidays.reduce((acc, holiday) => { 
    acc[holiday.name] = 0;
    return acc;
  }, {});
                              
  const addLeaveTypesDict = { 
    ...addLeaveTypesDictPart,
    ...addPublicHolidaysDictPart
  };

  let leave_type_ids = [];
  leaveTypes.forEach((leave_type) => { leave_type_ids.push(leave_type["id"]); });

  //LEAVE GROUPS
  const leaveGroupsColumns = [{ field: 'id', headerName: 'Group', width: 20 }].concat(
    leave_type_ids.map((leave_type_id) => { 
      return { 
        field: `${leave_type_id}`, 
        headerName: `${leaveTypes.find(item => item.id === leave_type_id)?.name}`, 
        width: 100, type: 'boolean', editable: true }; }));

  const addLeaveGroupsDict = { };
  leaveTypes.forEach((leave_type) => { addLeaveGroupsDict[leave_type["id"]] = ""; });

  //LEAVE BALANCES
  const leaveBalancesColumns = [
    // { field: 'id', headerName: 'Id', width: 20 },
    { field: 'employee_number', headerName: 'User', width: 100, editable: true },
  ].concat(
    leave_type_ids.map((leave_type_id) => { return { 
      field: `${leave_type_id}`, 
      headerName: `${leaveTypes.find(item => item.id === leave_type_id)?.name}`, 
      width: 100, 
      valueGetter: (params) => params.row.balances[`${leave_type_id}`]["available"], 
      editable: true }; }));

  const addLeaveBalancesDict = { "balances": {} };
  leaveTypes.forEach((leave_type) => { addLeaveBalancesDict["balances"][leave_type["id"]] = ""; });

  let leave_group_ids = [];
  leaveGroups.forEach((leave_group) => { leave_group_ids.push(leave_group["id"]); });

  //PUBLIC HOLIDAYS
  const publicHolidaysColumns = [
    { field: 'id', headerName: 'Id', width: 20 },
    { field: 'name', headerName: 'Name', width: 300, editable: true },
    { field: 'date', headerName: 'Date', width: 300, type: 'date', editable: true,
      valueGetter: (params) => new Date(params.row.date),
      renderCell: ({ row }) => (
        format(new Date(row.date), 'dd-MM-yyyy')
      )
     },
  ];

  const addPublicHolidaysDict = { name: "", date: new Date() };

  //EMPLOYEES
  const options = [
    { value: 1, label: 'Option 1' },
    { value: 2, label: 'Option 2' },
    { value: 3, label: 'Option 3' },
  ];

  const MultiSelectCell = ({ value, id, field, api }) => {
    const [selectedOptions, setSelectedOptions] = useState(value || []);
  
    const handleChange = (newOptions) => {
      setSelectedOptions(newOptions);
      api.setRowData(id, { ...api.getRow(id), [field]: newOptions }); // Update data
    };
  
    return (
      <Select
        isMulti
        value={selectedOptions}
        options={options}
        onChange={handleChange}
      />
    );
  };

  const employeesColumns = [
    { field: 'employee_number', headerName: 'EmpNo', width: 20 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'surname', headerName: 'Surname', width: 150 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'leave_group_id', headerName: 'Leave Group', width: 100, type: 'singleSelect', valueOptions: leave_group_ids, editable: true},
    { field: 'access_level', headerName: 'Access Level', width: 100, type: 'singleSelect', valueOptions: ["1", "2", "3"], editable: true},
    { field: 'stop_accrual',  headerName: 'Stop Accrual', width: 150, type: 'boolean', editable: true,
      valueGetter: (params) => params.row.employee_information.stop_accrual,
      valueSetter: (params) => {
        const updatedData = {...params.row};
        updatedData.employee_information.stop_accrual = params.value;
        return updatedData; 
      }
    },
  ];  

  //ACCRUAL RUNS
  const accrualRunsColumns = [
    // { field: 'id', headerName: 'Id', width: 20 },
    { field: 'month', headerName: 'Month', width: 300, editable: true },
    { field: 'run_number', headerName: 'Run Number', width: 300, editable: true },
    { field: 'total_runs', headerName: 'Total Runs', width: 300, editable: true },
  ];

  //BACKUPS
  const backupsColumns = [
    { field: 'id', headerName: 'Id', width: 20 },
    { field: 'filename', headerName: 'File Name', width: 300, editable: false },
    { field: 'download', headerName: 'Download', width: 100, 
      renderCell: ({ row }) => (
        <Button 
          onClick={() => handleDownloadBackup(row.filename)} 
          type="button" 
          color="secondary" 
          variant="contained"
        >
          Download
        </Button>
      )
    },
    { field: 'restore', headerName: 'Restore', width: 100, 
      renderCell: ({ row }) => (
        <Button 
          onClick={() => handleRestoreBackup(row.filename)} 
          type="button" 
          color="secondary" 
          variant="contained"
        >
          Restore
        </Button>
      )
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <CustomSnackBar />
      <Button 
          onClick={() => handleToggleMaintenanceMode()} 
          type="button" 
          color={maintenanceMode ? "secondary": "error"} 
          variant="contained"
        >
          {`Turn ${maintenanceMode ? "OFF": "ON"} maintenance mode`}
      </Button>

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="LEAVE TYPES"
        expanded={false}
        cardContent={
          <CustomDataGrid 
          rows={leaveTypes} 
          setRows={setLeaveTypes} 
          otherColumns={[leaveTypesColumns, addLeaveTypesDict]} 
          backendCRUD = {{
            "C": addLeaveType,
            "R": getLeaveTypes,
            "U": editLeaveType,
            "D": deleteLeaveRecord,
            "TYPE": "LEAVE TYPE",
          }}
          actions={true}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="LEAVE GROUPS"
        expanded={false}
        cardContent={
          <CustomDataGrid 
          rows={leaveGroups} 
          setRows={setLeaveGroups} 
          otherColumns={[leaveGroupsColumns, addLeaveGroupsDict]} 
          backendCRUD = {{
            "C": addLeaveGroup,
            "R": getLeaveGroups,
            "U": editLeaveGroup,
            "D": deleteLeaveRecord,
            "TYPE": "LEAVE GROUP",
          }}
          actions={true}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="LEAVE BALANCES"
        expanded={false}
        cardContent={
          <CustomDataGrid 
          rows={leaveBalances} 
          setRows={setLeaveBalances} 
          otherColumns={[leaveBalancesColumns, addLeaveBalancesDict]} 
          backendCRUD = {{
            "C": addLeaveBalance,
            "R": getLeaveBalances,
            "U": addLeaveBalance,
            "D": deleteLeaveRecord,
            "TYPE": "LEAVE BALANCE",
          }}
          actions={true}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="EMPLOYEES"
        expanded={false}
        cardContent={
          <CustomDataGrid
            rows={employees} 
            // rows={employees.map((employee) => ({...employee, id: [employee.employee_number] }))} 
            setRows={setEmployees} 
            otherColumns={[employeesColumns, ""]} 
            backendCRUD = {{
              "C": false,
              "R": getEmployees,
              "U": editEmployee,
              "D": false,
              "TYPE": false,
          }}
            actions={true}
          />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="PUBLIC HOLIDAYS"
        expanded={false}
        cardContent={
          <CustomDataGrid
            rows={publicHolidays} 
            setRows={setPublicHolidays} 
            otherColumns={[publicHolidaysColumns, addPublicHolidaysDict]} 
            backendCRUD = {{
              "C": addPublicHoliday,
              "R": getEmployees,
              "U": editPublicHoliday,
              "D": deleteLeaveRecord,
              "TYPE": "PUBLIC HOLIDAY",
          }}
            actions={true}
          />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="ACCRUAL RUNS"
        expanded={false}
        cardContent={
          <CustomDataGrid 
          rows={accrualRuns} 
          setRows={setAccrualRuns} 
          otherColumns={[accrualRunsColumns, []]} 
          backendCRUD = {{
            "C": null,
            "R": getAccrualRuns,
            "U": runAccrualRun,
            "D": null,
            "TYPE": "ACCRUAL RUNS",
          }}
          actions={false}
          extraButtons={[
            {icon: <AddIcon />, label: "Run", func:runAccrualRun, func_action: {"action":"run"}, func_type: "backup"},
            {icon: <RemoveIcon />, label: "Undo Last Accrual", func:runAccrualRun, func_action: {"action":"undo"}, func_type: "backup"}
          ]}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <input
        id="file-input"
        type="file"
        accept=".sql"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      <CollapsableCard 
        cardHeader="BACKUPS"
        expanded={false}
        cardContent={
          <>
            <CustomDataGrid
                rows={dbBackups}
                setRows={setDBBackups}
                otherColumns={[backupsColumns, []]}
                backendCRUD={{
                  "C": null,
                  "R": getAvailableBackups,
                  "U": null,
                  "D": null,
                  "TYPE": "BACKUP",
                }}
                actions={false}
                extraButtons={[
                  { icon: <Save />, label: "Backup", func: backupDB, func_variables: {}, func_type: "backup" },
                  { icon: <AddIcon />, label: "Restore", func: handleRestoreClick, func_variables: {}, func_type: "restore" },
                ]} 
              />
          </>
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

    </Box>
  );  
};

export default LeaveSettingsPage;