import { useEffect, useState, useRef } from "react";
import { Box, Button, TextField, MenuItem, Tooltip, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import format from 'date-fns/format'
import { addDays } from 'date-fns'

import "../../styles/styles.css";

import { addEvent, editEvent, workDuration, applyLeave, deleteLeaveTaken, editLeave } from "../../services/leaveService";
import DateRangeComp from "../../components/datepickers/DateRangeComp";
import Header from "../../components/Header";
import { useStateContext } from "../../contexts/ContextProvider";
import Popup from "../../components/Popup";
import CircleNumber from "../../components/CircleNumber";
import LoadingOverlay from "../global/LoadingOverlay";


const LeaveApplicationForm = ({ popupData }) => {
  const { employee, setOpenLeaveApplicationPopup, setOpenFailedPopup, range, setRange, duration, setDuration, setResultMessage, leaveBalances, setLeaveBalances,
          setAllLeaveTaken, leaveTypes, selectedLeaveType, setSelectedLeaveType, leaveGroups, isLoading, setIsLoading, leaveTaken, setLeaveTaken,
          selectedLeave, setSelectedLeave, openDeleteEventPopup, setOpenDeleteEventPopup, publicHolidays,
          setSnackbar } = useStateContext();

  const [selectedFile, setSelectedFile] = useState(null);
  const [partialDurationButtonText, setPartialDurationButtonText] = useState(range[0].manual_duration_enabled ? "Deactivate Partial Duration" : "Activate Partial Duration");

  const refDeleteEventPopup = useRef(null);

  const leave_group = leaveGroups.find(item => item.id === employee.leave_group_id)?.leave_type_ids;
  const filtered_leave_types = leaveTypes.filter((item) => leave_group.includes(item.id));
  const filtered_leave_balances = leaveBalances.find((item) => item.employee_number === employee.employee_number)?.balances;

  const isNonMobile = useMediaQuery("(min-width:600px)");
  
  // let selected = popupData;

  const applicants = [ { employee_number: employee.employee_number, name: employee.name + " " + employee.surname } ];

  const leave_type_start_time = leaveTypes.find((row) => row.id === selectedLeaveType)?.start_time;
  const leave_type_end_time = leaveTypes.find((row) => row.id === selectedLeaveType)?.end_time;
  const leave_type_duration = parseInt(leave_type_end_time.split(":")[0]) - parseInt(leave_type_start_time.split(":")[0]);

  const initialValues = {
    applicants : applicants,
    selected_applicant: applicants[0].employee_number,
    // leave_types: leaveTypes,
    leave_types: filtered_leave_types,
    //supposed to use selectedapplicatnt rather than employee.id
    leave_balances: filtered_leave_balances,
    selected_leave_type : filtered_leave_types[0].id,
    // selected_leave_type : leaveTypes[0].id,
    pickedDateInitial: '',
    pickedDateFinal: '',
    application_reason : "",
    leave_start_time: range[0].manual_duration_enabled ? (range[0].selected_times.start_time.split(":")[0] + ":" + range[0].selected_times.start_time.split(":")[1]) : leave_type_start_time,
    leave_end_time: range[0].manual_duration_enabled ? (range[0].selected_times.end_time.split(":")[0] + ":" + range[0].selected_times.end_time.split(":")[1]) : leave_type_end_time,
    // attachment : "Good",
  };

  // console.log("SLTO", selectedLeave);

  const handleFormSubmit = async (calendar_values) => {
    // console.log(range);
    // console.log("LAF", selected);
    setIsLoading(true);

    if(!range[0].manual_duration_enabled){
      calendar_values.pickedDateInitial = format(range[0].startDate, "yyyy-MM-dd")
      calendar_values.pickedDateFinal = format(range[0].endDate, "yyyy-MM-dd")
    } else {
      calendar_values.pickedDateInitial = format(range[0].startDate, "yyyy-MM-dd") + `T${range[0].selected_times.start_time}:00`;
      calendar_values.pickedDateFinal = format(range[0].endDate, "yyyy-MM-dd") + `T${range[0].selected_times.end_time}:00`;
      // calendar_values.pickedDateInitial = format(range[0].startDate, "yyyy-MM-dd'T'HH:mm:ss")
      // calendar_values.pickedDateFinal = format(range[0].endDate, "yyyy-MM-dd'T'HH:mm:ss")
    }
    // console.log("TYPE OF", typeof range[0].endDate)
    // console.log("SLT", selectedLeave);

    let data = {};
    let leave_response = null;

    if(selectedLeave.id){//we are editing leave
      data = {
        "employee_number": employee.employee_number,
        "edited_leave_id": selectedLeave.id,
        "edited_leave_startStr": calendar_values.pickedDateInitial,
        "edited_leave_endStr": calendar_values.pickedDateFinal,
        "duration": duration,
        "selected_leave_type":selectedLeave.extendedProps.leave_type_id,
        // "selected": selectedLeave,
        // "selected": { //look for copying getters and setters
        //   ...selectedLeave, 
        //   setStart: selectedLeave.calendaApiEventToUpdate.setStart, 
        //   setEnd: selectedLeave.calendaApiEventToUpdate.setEnd,
        //   setAllDay: selectedLeave.calendaApiEventToUpdate.setAllDay,
        //   allDay: !range[0].manual_duration_enabled },
        "selected": selectedLeave,
        "allDay": !range[0].manual_duration_enabled
      }

      leave_response = await editLeave(data);
    } else {
      data = {
        "employee_number": employee.employee_number,
        "selected": selectedLeave,
        "duration": duration,
        "attachment": selectedFile,
      }

      leave_response = await applyLeave(data, calendar_values);
    }


    //save to DB

    setOpenLeaveApplicationPopup(false);
    
    if (leave_response["status"] !== 200){
      setIsLoading(false);
      setResultMessage(leave_response["data"]["message"]);
      setOpenFailedPopup(true);
      return;
    }

    if(!range[0].manual_duration_enabled){
      calendar_values.pickedDateFinal = format(addDays(new Date(calendar_values.pickedDateFinal), 1), "yyyy-MM-dd")
      // calendar_values.pickedDateFinal = format(addDays(new Date(calendar_values.pickedDateFinal), 1), "yyyy-MM-dd'T'HH:mm:ssxxx")
    }
    data["leave_id"] = leave_response["data"]["leave_id"];

    setLeaveBalances(leave_response["data"]["new_leave_balances"]);
    setLeaveTaken(leave_response["data"]["employee_leave_taken"]);
    // setAllLeaveTaken(leave_response["data"]["all_leave_taken"]);
    // setLeaveBalances(leaveBalances.map((row) => (row.employee_number === employee.id ? JSON.parse(leave_response["data"]["new_leave_balances"]) : row)));
    setSelectedFile(null);

    let result = null;

    if(selectedLeave.id){//we are editing leave
      result = await editEvent(data, calendar_values);
    } else {
      result = await addEvent(data, calendar_values);
    }
  
    setSelectedLeave(null);
    setOpenLeaveApplicationPopup(false);
    if (!result["success"]){
      setIsLoading(false);
      setResultMessage(result["message"])
      setOpenFailedPopup(true);
    }
    
    setSnackbar({ children: 'Leave successfully applied', severity: 'success' });
    setIsLoading(false);
  };

  const handleLeaveSelect = (event, setFieldValue) => {
    setFieldValue(event.target.value)
    setSelectedLeaveType(event.target.value)
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleBrowseClick = () => {
    const fileInput = document.getElementById('file-input');
    fileInput.value = null; // Reset the value of the file input
    fileInput.click();
  };

  const handleManualDurationClick = (values) => {

    setRange([{
      ...range[0],
      manual_duration_enabled: !range[0].manual_duration_enabled, 
      leave_type_times: { start_time: leave_type_start_time, end_time: leave_type_end_time }, 
      selected_times: { start_time:values.leave_start_time, end_time:values.leave_end_time } 
    }]);
    // setManualDuration({
    //   enabled: true, 
    //   leave_type_times: { start_time: leave_type_start_time, end_time: leave_type_end_time }, 
    //   selected_times: { start_time:values.leave_start_time, end_time:values.leave_end_time } 
    // });

    setPartialDurationButtonText(partialDurationButtonText === "Activate Partial Duration" ? "Deactivate Partial Duration" : "Activate Partial Duration");

    // let newSelectedLeave = {...selectedLeave};
    // Object.defineProperties(newSelectedLeave, Object.getOwnPropertyDescriptors(selectedLeave));
    // let newSelectedLeave = {
      //   ...selectedLeave,
      //   allDay: false,
      // }

    // let newSelectedLeave = {
    //   ...selectedLeave,
    //   allDay: false,
    // }

    // if(selectedLeave.id){
    //   newSelectedLeave.startStr = selectedLeave.startStr;
    //   newSelectedLeave.endStr = selectedLeave.endStr;
    //   newSelectedLeave.extendedProps = selectedLeave.extendedProps;
    //   newSelectedLeave.id = selectedLeave.id;
    //   newSelectedLeave.title = selectedLeave.title;
    // }

    // setSelectedLeave({ ...newSelectedLeave });
    setSelectedLeave({ ...selectedLeave, allDay: false });
  }
  
  const handleCancelClick = () => {
    setSelectedLeave(null);
    setOpenLeaveApplicationPopup(false);
  }

  const handleDeleteClick = () => {
    setOpenDeleteEventPopup(true);
  }

  const handleDeleteEvent = async () => {
    setOpenDeleteEventPopup(false);
    setIsLoading(true);
    const response = await deleteLeaveTaken(selectedLeave.id, "LEAVE TAKEN");
    
    if (response["status"] !== 200){
      setResultMessage(response["data"]["message"])
      setOpenFailedPopup(true);
    } else {
      
      selectedLeave.calendaApiEventToUpdate.remove();
      setLeaveBalances(response["data"]["new_leave_balances"]);
      setLeaveTaken(response["data"]["employee_leave_taken"]);
      setOpenLeaveApplicationPopup(false);
      setSnackbar({ children: 'Leave successfully deleted', severity: 'success' });
    }
    setIsLoading(false);
  };

  const handlePartialChange = async (value) => {
    let range_to_set = { 
      ...range[0],
    };

    range_to_set.leave_type_times = { 
      start_time : leaveTypes.find((row) => row.id === selectedLeaveType)?.start_time,
      end_time : leaveTypes.find((row) => row.id === selectedLeaveType)?.end_time
    }

    if(value.name === "leave_start_time"){
      range_to_set.selected_times.start_time = value.value;
    } else {
      range_to_set.selected_times.end_time = value.value;
    }

    setRange([{ ...range_to_set }]);
  };

  useEffect(() => {
    // let worked_duration = workDuration(range, publicHolidays, selectedLeaveType, leaveTypes);
    // setDuration(worked_duration);

    let worked_duration_results = workDuration(range, publicHolidays, selectedLeaveType, leaveTypes);
    const worked_duration = worked_duration_results["duration"];
    setDuration(worked_duration);

    if (worked_duration_results["new_end_date"]){
      setRange([{
        ...range[0],
        endDate: new Date(worked_duration_results["new_end_date"])
      }])
    }
  }, [range, selectedLeave, selectedLeaveType]);

  useEffect(() => {
    selectedLeave.id ? setSelectedLeaveType(selectedLeave.extendedProps.leave_type_id) : setSelectedLeaveType(1);
  }, []);

  return (
    <Box m="20px">
      <Header title={(employee.name + " " + employee.surname).toUpperCase()} subtitle="Leave Application" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                id="applicant-name"
                select
                fullWidth
                defaultValue={values.applicants[0].employee_number}
                variant="filled"
                type="text"
                label="Applicant"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('selected_applicant', event.target.value)}
                name="applicant"
                // error={!!touched.applicant && !!errors.applicant}
                // helperText={touched.applicant && errors.applicant}
                sx={{ gridColumn: "span 4" }}
              >
                {values.applicants.map((applicant) => (
                  <MenuItem key={applicant.employee_number} value={applicant.employee_number}>
                    {(applicant.name).toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
              
              <TextField
                id="leave-type"
                select
                fullWidth
                defaultValue={initialValues["selected_leave_type"]}
                variant="filled"
                type="text"
                label="Leave Type"
                onBlur={handleBlur}
                onChange={(event) => handleLeaveSelect(event, setFieldValue.bind(null, 'selected_leave_type'))}
                // onChange={(event) => setFieldValue('selected_leave_type', event.target.value)}
                name="leave_type"
                // error={!!touched.leave_type && !!errors.leave_type}
                // helperText={touched.leave_type && errors.leave_type}
                sx={{ gridColumn: "span 4" }}
              >
                {values.leave_types.map((leave_type) => (
                  <MenuItem key={leave_type.id} value={leave_type.id}>
                    <Box display="flex" justifyContent="space-between" width="80%" gap="10px">
                      {values.leave_types.find(item => item.id === leave_type.id)?.name}
                      <Tooltip 
                        title={
                          <Typography variant="body2">
                            Available Balance: {parseFloat((values.leave_balances[leave_type.id]["available"]).toFixed(2))}
                            <br />
                            Pending Approval: {parseFloat((values.leave_balances[leave_type.id]["pending"]).toFixed(2))}
                            <br />
                            Total Balance: {values.leave_balances[leave_type.id]["available"]
                                            + values.leave_balances[leave_type.id]["pending"]}
                          </Typography>
                        }
                      >
                        <Box display="flex" justifyContent="end" width="30%" gap="10px">
                            {(values.leave_balances[leave_type.id]["available"]) > 0 
                              ? <CircleNumber 
                                  number={parseFloat((values.leave_balances[leave_type.id]["available"]).toFixed(2))}
                                  color={"green"} 
                                /> 
                              : <Box width="15%" />}
                            {(values.leave_balances[leave_type.id]["pending"]) > 0 
                              ? <CircleNumber 
                                  number={parseFloat((values.leave_balances[leave_type.id]["pending"]).toFixed(2))} 
                                  color={"yellow"} 
                                /> 
                              : <Box width="15%" />}
                        </Box>
                      </Tooltip>
                    </Box>
                  </MenuItem>
                ))}
              </TextField>

              {/* <DateRangeComp /> */}

              <Box 
                sx={{ gridColumn: "span 3" }}
              >
                <LocalizationProvider 
                  dateAdapter={AdapterDayjs}
                >
                  <DateRangeComp />
                </LocalizationProvider>
              </Box>

              <TextField 
                disabled
                fullWidth
                variant="filled"
                type="text"
                label="Duration"
                onBlur={handleBlur}
                onChange={handleChange}
                value={duration}
                // value={differenceInBusinessDays(new Date(format(range[0].endDate, "yyyy-MM-dd'T'HH:mm:ssxxx")), new Date(format(range[0].startDate, "yyyy-MM-dd'T'HH:mm:ssxxx"))) + 1}
                name="duration"
                // error={!!touched.duration && !!errors.duration}
                // helperText={touched.duration && errors.duration}
              />

              {/* MANUAL DURATION */}
              <TextField
                select
                fullWidth
                variant="filled"
                label="Start Date From"
                onChange={(event) => {
                  handleChange(event);
                  handlePartialChange(event.target)}
                }
                name="leave_start_time"
                value={values.leave_start_time}
                sx={{ gridColumn: "span 1" }}
                disabled={partialDurationButtonText === "Activate Partial Duration" ? true : false}
              >
                {
                  (Array.from({ length: leave_type_duration * 2 }, (_, index) => {
                    const hour = Math.floor(index / 2) + parseInt(leave_type_start_time.split(":")[0]);
                    // const hour = Math.floor(index / 2) + 8;
                    const minutes = index % 2 === 0 ? '00' : '30';
                    return `${hour.toString().padStart(2, '0')}:${minutes}`;
                  })).map((applicant) => (
                    <MenuItem key={applicant} value={applicant}>
                      {applicant}
                    </MenuItem>
                  ))
                }
              </TextField>
              <TextField
                select
                fullWidth
                variant="filled"
                label="End Date To"
                onChange={(event) => {
                  handleChange(event);
                  handlePartialChange(event.target)}
                }
                name="leave_end_time"
                value={values.leave_end_time}
                sx={{ gridColumn: "span 1" }}
                disabled={partialDurationButtonText === "Activate Partial Duration" ? true : false}
              >
                {
                  (Array.from({ length: leave_type_duration * 2 + 1 }, (_, index) => {
                    const hour = Math.floor(index / 2) + parseInt(leave_type_start_time.split(":")[0]);
                    // const hour = Math.floor(index / 2) + 8;
                    const minutes = index % 2 === 0 ? '00' : '30';
                    return `${hour.toString().padStart(2, '0')}:${minutes}`;
                  })).map((applicant) => (
                    <MenuItem key={applicant} value={applicant}>
                      {applicant}
                    </MenuItem>
                  ))
                }
              </TextField>
              <Button
                variant="contained"
                onClick={() => handleManualDurationClick(values)}
                sx={{ gridColumn: "span 2" }}
              >
                {partialDurationButtonText}
              </Button>

              <Box
                sx={{ gridColumn: "span 4"}}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Reason"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.application_reason}
                  name="application_reason"
                  // error={!!touched.reason && !!errors.reason}
                  // helperText={touched.reason && errors.reason}
                  sx={{ gridColumn: "span 4" }}
                />

                <Typography variant="h6" fontWeight="bold">
                  Attachments
                </Typography>
                <input
                  id="file-input"
                  type="file"
                  accept=".pdf,.doc,.docx"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />

                <Button variant="contained" color="primary" onClick={handleBrowseClick}>
                  Browse
                </Button>{" "}
                {selectedFile && <span>{selectedFile.name}</span>} {" "}
                {selectedFile && <span sx={{justifyContent: "flex-end"}}><Button variant="contained" color="error"onClick={()=> setSelectedFile(null)}>X</Button></span>}
              </Box>

              {/* <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Attachments"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.attachment}
                name="attachment"
                // error={!!touched.attatchment && !!errors.attatchment}
                // helperText={touched.attatchment && errors.attatchment}
                sx={{ gridColumn: "span 4" }}
              /> */}

            </Box>

            <Box display="flex" justifyContent="space-between" width="100%" gap="10px" mt="20px">
                <Button onClick={handleCancelClick} type="button" color="secondary" variant="contained">
                    Cancel
                </Button>
                {selectedLeave.id && ( //checking if it's a new event or an exisiting event. If exisiting, show delete
                  <Button onClick={handleDeleteClick} type="button" color="secondary" variant="contained" disabled={selectedLeave.extendedProps.status !== "pending" ? true : false}>
                      Delete
                  </Button>
                )}
                <Button type="submit" color="secondary" variant="contained" disabled={(selectedLeave.id) ? (selectedLeave.extendedProps.status !== "pending" ? true : false) : false}>
                    {selectedLeave.id ? 'Edit' : 'Submit'} Application
                </Button>
            </Box>
          </form>
        )}
      </Formik>

      <div ref={refDeleteEventPopup}>
        {openDeleteEventPopup && 
          <Popup 
            openPopup = {openDeleteEventPopup}
            setOpenPopup = {setOpenDeleteEventPopup}
          >
            <Typography style={{ textAlign: 'center' }}>
              Delete 
              <br />
              {selectedLeave.title}
              <br />
              {selectedLeave.startStr} - {selectedLeave.endStr}
            </Typography>
            <Box display="flex" justifyContent="space-between" width="100%" gap="10px" mt="20px">
                <Button onClick={() => setOpenDeleteEventPopup(false)} type="button" color="secondary" variant="contained">
                    No
                </Button>
                <Button onClick={handleDeleteEvent} type="button" color="secondary" variant="contained">
                    Ok
                </Button>
            </Box>
          </Popup>
        }
      </div>

      {/* Loading overlay and spinner */}
      {/* {isLoading && ( */}
        {/* // <div className="loading-overlay"> */}
          {/* <div className="spinner" style={{ animation: 'spin 1s infinite linear' }}></div> */}
          {/* <div className="spinner" ></div> */}
        {/* </div> */}
      {/* // )} */}
      <LoadingOverlay isLoading={isLoading} />
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  // leave_type: yup.string().required("required"),
  // reason: yup.string().required("required"),
  // attatchment: yup.string().required("required"),
  // email: yup.string().email("invalid email").required("required"),
  // contact: yup
  //   .string()
  //   .matches(phoneRegExp, "Phone number is not valid")
  //   .required("required"),
  // address1: yup.string().required("required"),
  // address2: yup.string().required("required"),
});

export default LeaveApplicationForm;
