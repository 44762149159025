import axios from 'axios';

// const backend_url = "http://localhost/api";
const backend_url = "https://ess-creativehr-backend.creativehr.co.zw/api";

const api = axios.create({
  baseURL: backend_url,
  withCredentials: true, // Enable cookies for cross-origin requests
  headers: {
    // 'Content-Type': 'application/json',
    // 'X-Requested-With': 'XMLHttpRequest', // Add X-Requested-With header for Laravel to recognize AJAX requests
  },
});

const fetchCsrfToken = async () => {
  try {
    const response = await api.get('/get-csrf-token');
    return response.data.csrfToken;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
    throw error; // Propagate the error to the caller
  }
};

const setCsrfAndTokenHeader = async (method) => {
  // console.log("METHOD", method);
  if (method.toLowerCase() !== 'get') {
    const csrfToken = await fetchCsrfToken();
    // console.log("RESPONSE", csrfToken);
    api.defaults.headers['X-CSRF-TOKEN'] = csrfToken;
    // console.log("API", api.defaults);
  }

  const url = window.location.pathname;
  // console.log("SCTH", url);
  const isLoginUrl = ['/login', '/'].includes(url);
  if (!isLoginUrl) {
    api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  }
};

const setBlobForFileDownload = () => {
  api.defaults.responseType = 'blob';
}
const unsetBlobForFileDownload = () => {
  api.defaults.responseType = '';
}

const ApiService = {
  get: (url) => api.get(url),
  post: (url, data) => api.post(url, data),
  put: (url, data) => api.put(url, data),
  delete: (url, data) => api.delete(url, data),
};

export { ApiService, setCsrfAndTokenHeader, setBlobForFileDownload, unsetBlobForFileDownload };
